export const manufacturerPath = "/geo2/manufacturer";
export const cableModelPath = "/geo2/cablemodel";
export const cableModelSimplePath = cableModelPath + "/simple";
export const connectorModelPath = "/geo2/connectormodel";
export const connectorModelSimplePath = connectorModelPath + "/simple";
export const splitterModelPath = "/geo2/splittermodel";
export const splitterModelSimplePath = splitterModelPath + "/simple";
export const switchModelPath = "/geo2/switchmodel";
export const switchModelSimplePath = switchModelPath + "/simple";
export const amendmentModelPath = "/geo2/ceomodel";
export const amendmentModelSimplePath = amendmentModelPath + "/simple";
export const ctoModelPath = "/geo2/ctomodel";
export const ctoModelSimplePath = ctoModelPath + "/simple";
export const dioModelPath = "/geo2/diomodel";
export const cordoalhaModelPath = "/geo2/cordoalhamodel";
export const cordoalhaModelSimplePath = cordoalhaModelPath + "/simple";
export const hardwareKitPath = "/geo2/hardwarekit";
export const hardwareKitSimplePath = hardwareKitPath + "/simple";
export const hardwareItemsPath = "/geo2/hardwareitems";
export const changeLogPath = "/geo2/changelog/main";

export const paymentPath = "/geo2/payment";

export const eventPath = "/geo2/event";
export const eventUserPath = "/geo2/event/user";
export const eventPagePath = "/geo2/event/page";
export const eventPageEventPath = "/geo2/event/pageEvent/";

export const ipPath = "/geo2/ip";
export const oltModelPath = "/geo2/oltmodel";
export const boardModelPath = "/geo2/boardmodel";
export const searchEquipmentByName = "/geo2/equipment/search?text=";
export const companyResumed = "/geo2/company/simple";
export const companyFind = "/geo2/company/find";

export const loginPath = "/geo2/login";
export const autoLoginPath = "/geo2/login/validate";
export const companyChoose = "/geo2/user/changecompany/";

// export const ticketPath = "/geo2/ticket";
export const userPath = "/geo2/user";
export const userListPath = "/geo2/user/userList";
export const userCompanyPath = "/geo2/user/company";
export const profilePath = "/geo2/profile";
export const forgotPasswordPath = "/geo2/forgotpassword";
export const functionalityPath = "/geo2/functionality";
export const actionPath = "/geo2/action";
export const layerPath = "/geo2/layer";

export const cableModelSizeReportPath =
  "/geo2/report/cablesizereport/cablemodels";
export const ctoReportPath = "/geo2/report/ctousage";
export const ceoReportPath = "/geo2/report/ceousage";
export const poleReportPath = "/geo2/report/pole";
export const verticalCondominiumReportPath = "/geo2/report/verticalcondominium";

export const clientReportAddressPath = "/geo2/report/clientusage";
export const clientReportSignalPath = "/geo2/report/clientsignal";

export const nearbyClientsPointPath = "/geo2/equipment/nearbyclientspoint";

export const changePasswordPath = "/geo2/user/changepassword";
export const configurationCompanyPath = "/geo2/company/configurationcompany";

export const folderPath = "/geo2/folder";
export const folderSimplePath = folderPath + "/simple";

export const deleteImagePath = "/imageserver/delete/";
export const uploadImagePath = "imageserver/upload";
export const sizeImagePath = "imageserver/size";

export const doorsReservePath = "/geo2/doorreserve";
export const doorsReserveHistoryPath = doorsReservePath + "/history";
export const damagedFiberListPath = "/geo2/fiber/damagedfiber";

export const messagePath = "/geo2/message";
export const messageUserPath = messagePath + "/messageuser";
export const messageSimplePath = messagePath + "/simple";
export const clientWithoutOltAssociationPath = "/geo2/oltData/client/invalid";

export const reportPath = "/geo2/report";
export const filePath = "/importer/file";

export const faqPath = "geo2/faq";
export const faqPagePath = faqPath + "/page";

export const oltDirectPath = "/geo2/oltdirect/simple";
export const oltDirectPathList = "/geo2/oltdirect/oltlist";
export const oltDataEquipmentPath = "/geo2/oltdata/equipment";
export const oltSignalHistoryPath = "/geo2/oltdirect/history"
export const clientWithoutConnectionPath = "/geo2/sitedirect/withoutconnection";
export const clientConnectionPath = "/geo2/sitedirect/connection";
export const GoogleKeyTestPath = "/geo2/google";

export const loggingPath = "/geo2/logging";
export const eventTypePath = "/geo2/logging/type";
export const eventActionPath = "/geo2/logging/action";
export const kmlExtractListPath = "/geo2/extract";

export const popPath = id => {
  return "/geo2/folder/" + id + "/pop";
};

export const sitePath = id => {
  return "/geo2/folder/" + id + "/site";
};

export const polePath = id => {
  return "/geo2/folder/" + id + "/pole";
};

export const polygonPath = id => {
  return "/geo2/folder/" + id + "/polygon";
};

export const commentMapPath = id => {
  return "/geo2/folder/" + id + "/commentmap";
};

export const cablePath = id => {
  return "/geo2/folder/" + id + "/cable";
};

export const amendmentPath = id => {
  return "/geo2/folder/" + id + "/ceo";
};

export const ctoPath = id => {
  return "/geo2/folder/" + id + "/cto";
};

export const findClientPath = id => {
  return "/geo2/equipment/" + id + "/connectedclients";
};

export const technicalReservePath = id => {
  return "/geo2/folder/" + id + "/technicalReserve";
};

export const virtualCircuitPath = id => {
  return "/geo2/folder/" + id + "/virtualcircuit";
};

export const cablePointPath = id => {
  return "/geo2/folder/" + id + "/cable/point";
};

export const cordoalhaPath = id => {
  return "/geo2/folder/" + id + "/cordoalha";
};

export const maintenancePointPath = id => {
  return "/geo2/folder/" + id + "/maintenancepoint";
};

export const verticalCondominiumPath = id => {
  return "/geo2/folder/" + id + "/verticalcondominium";
};

export const equipmentLimitPath = id => {
  return "/geo2/folder/" + id + "/equipmentLimit";
};

export const equipmentLimitExistsPath = (folderId, equipmentId) => {
  return (
    "/geo2/folder/" + folderId + "/equipmentLimit/" + equipmentId + "/exists"
  );
};

export const ctoCountReportPath = id => {
  return "/geo2/report/folder/" + id + "/ctocount";
};
export const ceoCountReportPath = id => {
  return "/geo2/report/folder/" + id + "/ceocount";
};
export const clientReportPath = id => {
  return "/geo2/report/folder/" + id + "/sitecount";
};
export const cableSizeReportPath = id => {
  return "/geo2/report/folder/" + id + "/cablesizereport";
};
export const doorCountReportPath = id => {
  return "/geo2/door/folder/" + id + "/countreport";
};
export const poleCountReportPath = id => {
  return "/geo2/report/folder/" + id + "/polecount";
};

export const folderMovePath = (baseId, destinyId) => {
  return folderPath + "/move/" + baseId + "/to/" + destinyId;
};

export const equipmentSimplePath = id => {
  return "/geo2/equipment/" + id + "/simple";
};

export const damagedFiberPath = fiberId => {
  return "/geo2/fiber/" + fiberId + "/damagedfiber";
};

export const cableGeometryPath = (folderId, id) => {
  return cablePath(folderId) + "/" + id + "/geometry";
};

export const dioPath = id => {
  return "/geo2/equipment/" + id + "/dio";
};

export const viabilityEquipmentPath = (id, isFindInternalEquipment) => {
  if(!isFindInternalEquipment)
    isFindInternalEquipment = false
  return "/geo2/equipment/" + id + "/nearby?isFindInternalEquipment=" + isFindInternalEquipment;
};

export const viabilityEquipmentCondominiumPath = (id,isFindExternalEquipment) => {
  if(!isFindExternalEquipment)
    isFindExternalEquipment = false
  return "/geo2/equipment/" + id + "/nearbyposition?isFindExternalEquipment=" + isFindExternalEquipment;
};
export const viabilityGeometryPath = (isFindInternalEquipment) => {
  if(!isFindInternalEquipment)
    isFindInternalEquipment = false

  return "/geo2/equipment/nearbypoint?isFindInternalEquipment=" + isFindInternalEquipment;
};

export const cablesInfoPath = id => {
  return "/geo2/equipment/" + id + "/cablesinfo";
};

export const commentPath = id => {
  return "/geo2/equipment/" + id + "/comment";
};

export const contactPath = id => {
  return "/geo2/equipment/" + id + "/contact";
};

export const blockPath = id => {
  return "/geo2/equipment/" + id + "/block";
};

export const imagePath = id => {
  return "/geo2/equipment/" + id + "/image";
};

export const imageMaintenancePath = id => {
  return "/geo2/maintenancepoint/" + id + "/image";
};

export const polygonMovePath = (folderId, id) => {
  return polygonPath(folderId) + "/" + id + "/move";
};

export const equipmentLimitMovePath = (folderId, id) => {
  return equipmentLimitPath(folderId ? folderId : 0) + "/" + id + "/move";
};

export const poleMovePath = (folderId, id) => {
  return polePath(folderId) + "/" + id + "/move";
};

export const commentMapMovePath = (folderId, id) => {
  return commentMapPath(folderId) + "/" + id + "/move";
};

export const cableMovePath = (folderId, id) => {
  return cablePath(folderId) + "/" + id + "/move";
};

export const equipmentMovePath = id => {
  return "/geo2/equipment/" + id + "/move";
};

export const virtualCircuitMovePath = (folderId, id) => {
  return virtualCircuitPath(folderId) + "/" + id + "/move";
};

export const cordoalhaMovePath = (folderId, id) => {
  return cordoalhaPath(folderId) + "/" + id + "/move";
};

export const maintenancePointMovePath = (folderId, id) => {
  return maintenancePointPath(folderId) + "/" + id + "/move";
};

export const splitterPath = id => {
  return "/geo2/equipment/" + id + "/splitter";
};

export const switchPath = id => {
  return "/geo2/equipment/" + id + "/switch";
};

export const folderEqpReportPath = (id, type) => {
  let path = "/geo2/folder/" + id + "/report";
  if (type) path += "/" + type;
  return path;
};

export const folderCapexPath = id => {
  return "/geo2/folder/" + id + "/capex";
};

export const folderUserPath = id => {
  return "/geo2/folder/" + id + "/user";
};

export const folderNotUserPath = id => {
  return "/geo2/folder/" + id + "/notuser";
};

export const userFoldersPath = id => {
  return "/geo2/user/" + id + "/folder";
};

export const diagramPath = id => {
  return "/geo2/fiber/" + id + "/route/d3";
};

export const fiberEquipamentPath = id => {
  return "/geo2/fiber/" + id + "/equipments";
};

export const diagramResumedPath = id => {
  return diagramPath(id) + "/resumed";
};

export const doorsEquipmentPath = id => {
  return "/geo2/equipment/" + id + "/doors";
};

export const fiberCablePath = (folderId, cableId) => {
  return cablePath(folderId) + "/" + cableId + "/fiber";
};

export const eqpEdgesPath = (folderId, cableId) => {
  return cablePath(folderId) + "/" + cableId + "/eqpEdges";
};

export const amendmentFolderPath = id => {
  return "/geo2/folder/" + id + "/ceo";
};

export const ctoFolderPath = id => {
  return "/geo2/folder/" + id + "/cto";
};

export const cableFolderPath = id => {
  return "/geo2/folder/" + id + "/cable";
};

export const cablePointFolderPath = id => {
  return "/geo2/folder/" + id + "/cable/point";
};

export const technicalReserveFolderPath = id => {
  return "/geo2/folder/" + id + "/technicalreserve";
};

export const cableFolderMovePath = (folderId, id) => {
  return "/geo2/folder/" + folderId + "/cable/" + id + "/move";
};

export const equipmentFolderMovePath = (folderId, id) => {
  return "/geo2/folder/" + folderId + "/equipment/" + id + "/move";
};

export const cablesInfoFolderPath = (folderId, equipmentId) => {
  return (
    "/geo2/folder/" + folderId + "/equipment/" + equipmentId + "/cablesinfo"
  );
};

export const connectionDiagramPath = id => {
  return "/geo2/equipment/" + id + "/connectionDiagram";
};

export const connectionDiagramEqpInternalPath = (equipmentId, id) => {
  return "/geo2/equipment/" + equipmentId + "/equipmentInternal/" + id;
};

export const connectionDiagramCablePath = (equipmentId, folderId, id) => {
  return (
    "/geo2/equipment/" + equipmentId + "/folder/" + folderId + "/cable/" + id
  );
};

export const cablesRoutePath = id => {
  return "/geo2/fiber/" + id + "/route/cables";
};

export const OltBoardPath = id => {
  return "/geo2/equipment/" + id + "/oltboard";
};
export const oltPath = id => {
  return "/geo2/equipment/" + id + "/olt";
};

export const connectionsPath = id => {
  return "/geo2/equipment/" + id + "/connection";
};

export const clientDeactivatePath = id => {
  return "/geo2/sitedirect/" + id + "/deactivate";
};

export const siteDirectPath = id => {
  return "/geo2/sitedirect/" + id;
};

export const oltDataPath = id => {
  return "/geo2/olt/" + id + "/oltdata";
};

export const oltDataListPath = id => {
  return "/geo2/olt/" + id + "/oltdata/oltdatalist";
};

export const oltDataClientPath = id => {
  return "/geo2/oltdata/" + id + "/client";
};

export const otdrPath = id => {
  return "/geo2/equipment/" + id + "/otdr";
};

export const equipmentMoveFolderPath = "/geo2/equipment/movefolder";

export const previewCtoModelPath = id => {
  return "/geo2/modelpreview/" + id + "/ctomodel";
};

export const previewCeoModelPath = id => {
  return "/geo2/modelpreview/" + id + "/ceomodel";
};

export const previewCableModelPath = id => {
  return "/geo2/modelpreview/" + id + "/cableModel";
};

export const previewSplitterModelPath = id => {
  return "/geo2/modelpreview/" + id + "/splitterModel";
};

export const previewDioModelPath = id => {
  return "/geo2/modelpreview/" + id + "/dioModel";
};

export const previewCordoalhaModelPath = id => {
  return "/geo2/modelpreview/" + id + "/cordoalhaModel";
};

export const previewBoardModelPath = id => {
  return "/geo2/modelpreview/" + id + "/boardModel";
};

export const previewOltModelPath = id => {
  return "/geo2/modelpreview/" + id + "/oltModel";
};

export const previewHardwareKit = id => {
  return "/geo2/modelpreview/" + id + "/hardwareKit";
};
